import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteContactsService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getByBuildingId(buildingId: number) {
    return this.httpClient.get('/api/siteContacts/building/' + buildingId)
                          .pipe(map(data => this.addDefaultImages(data)));
  }

  addDefaultImages(siteContacts) {
    for (let key of Object.keys(siteContacts)) {
      if (['customer_contact_1', 'customer_contact_2', 'data_contact_1', 'data_contact_2'].includes(key)) {
        if (siteContacts[key] && siteContacts[key].imageUrl == null) {
          siteContacts[key].imageUrl = 'https://pbs.twimg.com/profile_images/972641280995004416/mFG3MEbO_400x400.jpg';
        }
      }
    }
    return siteContacts;
  }

  getAll() {
    return this.httpClient.get<[any]>('/api/siteContacts/')
      .pipe(map(data =>
        data.sort(
          (a: any, b: any) => a.site.name.toString().localeCompare(b.site.name)
        )
      ));
  }

  setHandleInsufficientDataQuality(siteId: string, description: string) {
    return this.httpClient.put('/api/siteContacts/changeHandleInsufficientDataQuality/' + siteId
                              , { siteId: siteId, handleInsufficientDataQuality: description }
                              );
  }
}

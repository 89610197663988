import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RuleTypeService {

  constructor (private httpClient: HttpClient) {}

  getAll() {
    return this.httpClient.get('/api/ruletypes/');
  }

  get(id) {
    return this.httpClient.get('/api/ruletypes/' + id);
  }
}

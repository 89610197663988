export class PointType {
  static readonly ELECTRICITY_USAGE = 566;
  static readonly PV_PRODUCTION = 588;
  static readonly PV_INTERMEDIATE_VALUE_1 = 609;
  static readonly PV_INTERMEDIATE_VALUE_2 = 610;
  static readonly PV_INTERMEDIATE_VALUE_3 = 611;
  static readonly PV_INTERMEDIATE_VALUE_4 = 612;
  static readonly PV_INTERMEDIATE_VALUE_5 = 613;
  static readonly PV_INTERMEDIATE_VALUE_6 = 787;
  static readonly PV_INTERMEDIATE_VALUE_7 = 1049;
  static readonly PV_INTERMEDIATE_VALUE_8 = 1050;
  static readonly PV_INTERMEDIATE_VALUE_9 = 1051;
  static readonly PV_INTERMEDIATE_VALUE_10 = 1052;
  static readonly PV_INTERMEDIATE_VALUE_11 = 1053;
  static readonly PV_INTERMEDIATE_VALUES = [
    PointType.PV_INTERMEDIATE_VALUE_1,
    PointType.PV_INTERMEDIATE_VALUE_2,
    PointType.PV_INTERMEDIATE_VALUE_3,
    PointType.PV_INTERMEDIATE_VALUE_4,
    PointType.PV_INTERMEDIATE_VALUE_5,
    PointType.PV_INTERMEDIATE_VALUE_6,
    PointType.PV_INTERMEDIATE_VALUE_7,
    PointType.PV_INTERMEDIATE_VALUE_8,
    PointType.PV_INTERMEDIATE_VALUE_9,
    PointType.PV_INTERMEDIATE_VALUE_10,
    PointType.PV_INTERMEDIATE_VALUE_11,
  ];
}

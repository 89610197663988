import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteContactsService } from '../../services/site-contacts.service';
import {RuleTypeService} from '../../services/rule-type.service';

@Component({
  templateUrl: './rule-type-details.component.html',
  styleUrls: ['./rule-type-details.component.scss']
})

export class RuleTypeDetailsComponent implements OnInit {
  ruleTypeId: number;
  ruleTypeDetails: any = {description: 'Bezig met laden', ruleTypeCauses: [], ruleTypeConsequences: []};

  constructor(
    public modal: NgbActiveModal,
    private siteContactsService: SiteContactsService,
    private ruleTypeService: RuleTypeService,
  ) { }

  ngOnInit() {
     this.getRuleTypeInfo(this.ruleTypeId);
  }

  getRuleTypeInfo(ruleTypeId: number) {
    this.ruleTypeService.get(ruleTypeId).subscribe(
      data => {this.ruleTypeDetails = data;}
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UtilsService } from './utils.service';

@Injectable()
export class EventsService {

  constructor(private httpClient: HttpClient) {}

  getSubscriptions(siteId, userId) {

    const httpParams = new HttpParams({
      fromObject: {
        'siteId': siteId,
        'userId': userId
      }
    });

    return this.httpClient.get('/api/rules/subscriptions', {
      params: httpParams
    });
  }

  saveSubscriptions(siteId, userId, subscriptions: any) {
    const httpParams = new HttpParams({
      fromObject: {
        siteId: siteId,
        userId: userId
        }
    });
    return this.httpClient.put('/api/rules/subscriptions', subscriptions, { params: httpParams })
    /*.pipe(catchError(this.handleError))*/.subscribe(response => {
        });
  }

  getEvent(rulesId, EquipmentType, EquipmentId, time, startAndEndDate) {

    const httpParams = new HttpParams({
      fromObject: {
        'ruleId': rulesId,
        'equipmentType': EquipmentType,
        'equipmentId': EquipmentId,
        'from': UtilsService.DateToYYYYMMDD_HHMMSS(startAndEndDate.startDate),
        'to': UtilsService.DateToYYYYMMDD_HHMMSS(startAndEndDate.endDate)
      }
    });

    return this.httpClient.get('/api/rules/data/eventdetails', {
      params: httpParams
    });
  }

  getEventBySite(siteId, fromDate, toDate) {
    const httpParams = new HttpParams({
      fromObject: {
        'siteId': siteId,
        'from': UtilsService.DateToYYYYMMDD_HHMMSS(fromDate),
        'to': UtilsService.DateToYYYYMMDD_HHMMSS(toDate)
      }
    });
    return this.httpClient.get<[any]>('/api/rules/sites/events', {
      params: httpParams
    });
  }

  getAllEvents() {
    return this.httpClient.get('/api/rules/data/events', {})
    .pipe(map((data: any) => data.subscribe(response => response)));
  }
  
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BiReportService {

  constructor(private httpClient: HttpClient) {}

  getReports(siteId: string) {
    const httpParams = new HttpParams({
          fromObject: {
        siteId: siteId
      }
    });
    return  this.httpClient.get<any>('/api/portal/bireports', { params: httpParams })
      .pipe(map(data =>
        data.sort((a: any, b: any) =>
        a.name.toString().localeCompare(b.name))
        ));
    }

   get(id: number) {
     return this.httpClient.get('/api/portal/bireports/' + id);
    }
}


